import { AccountType } from 'constants/user';
import {
  GetCoachAllPlayersReflectionsQuery,
  GetCoachReflectionsBySubmitterIdQuery,
  GetPlayerReflectionByPlayerIdQuery,
  LessonPlayerSkillAssessmentCategoriesEnum,
  LessonPlayerSkillAssessments,
  LessonPlayerSkillAssessmentsSkills,
  UserProfiles,
  VideoAnalysisReviewStatusesEnum,
} from 'types/generated/client';
import { toSentenceCase } from 'utils/shared/string/toSentenceCase';
import {
  AvatarInfoType,
  FutureSkillsProps,
  MediaType,
  ProcessedConfidenceSkillsProps,
  ProcessedReflection,
  ReflectionDetail,
} from './props';

const categorizeSkills = (skills: LessonPlayerSkillAssessmentsSkills[]) => {
  return skills.reduce(
    (acc, skill) => {
      if (skill.category === LessonPlayerSkillAssessmentCategoriesEnum.Positive) {
        skill.skill && acc.positive.add(skill.skill);
      } else if (skill.category === LessonPlayerSkillAssessmentCategoriesEnum.Negative) {
        skill.skill && acc.negative.add(skill.skill);
      }
      return acc;
    },
    { positive: new Set<string>(), negative: new Set<string>() },
  );
};

const getTimeSortedSkills = (skills: LessonPlayerSkillAssessmentsSkills[]) => {
  const relevantSkills = skills.filter(
    (skill) =>
      skill.category === LessonPlayerSkillAssessmentCategoriesEnum.Positive ||
      skill.category === LessonPlayerSkillAssessmentCategoriesEnum.Negative,
  );

  return relevantSkills.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );
};

const getMostRecentSkills = (
  skills: LessonPlayerSkillAssessmentsSkills[],
): ProcessedConfidenceSkillsProps['mostRecent'] => {
  // Filter only POSITIVE and NEGATIVE skills and sort by createdAt
  const sortedSkills = getTimeSortedSkills(skills);

  // Get the most recent positive skill (if it exists)
  const mostRecentPositive = sortedSkills.find(
    (skill) => skill.category === LessonPlayerSkillAssessmentCategoriesEnum.Positive,
  );

  // Get the most recent negative skill (if it exists)
  const mostRecentNegative = sortedSkills.find(
    (skill) => skill.category === LessonPlayerSkillAssessmentCategoriesEnum.Negative,
  );

  return {
    positive: mostRecentPositive ? [mostRecentPositive.skill] : [],
    negative: mostRecentNegative ? [mostRecentNegative.skill] : [],
  };
};
const getGeneralSkills = (
  skills: LessonPlayerSkillAssessmentsSkills[],
): ProcessedConfidenceSkillsProps['general'] => {
  const categorized = categorizeSkills(skills);
  return {
    positive: Array.from(categorized.positive),
    negative: Array.from(categorized.negative),
  };
};

export const processLessonDataConfidence = (
  lessonData: LessonPlayerSkillAssessments[],
): ProcessedConfidenceSkillsProps => {
  const allSkills = lessonData.flatMap((lesson) => lesson.lessonPlayerSkillAssessmentsSkills);

  return {
    mostRecent: getMostRecentSkills(allSkills),
    general: getGeneralSkills(allSkills),
  };
};

export const getFutureSkills = (lessons: LessonPlayerSkillAssessments[]): FutureSkillsProps[] => {
  // Map each lesson to its relevant NEXT_GOAL skills and the common notedDate
  const futureSkillsData = lessons
    .map((lesson) => {
      const nextGoalSkills = lesson.lessonPlayerSkillAssessmentsSkills
        .filter((skill) => skill.category === LessonPlayerSkillAssessmentCategoriesEnum.NextGoal)
        .map((skill) => skill.skill)
        .filter(Boolean) as string[]; // Ensure we only take non-null skill names

      // If there are no NEXT_GOAL skills, skip this lesson
      if (nextGoalSkills.length === 0) return null;

      const notedDate = lesson.lessonPlayerSkillAssessmentsSkills[0].createdAt;

      return {
        lessonId: lesson.lessonId,
        id: lesson?.id,
        notedDate,
        skills: nextGoalSkills,
        coachAvatarInfo: {
          name: lesson?.lessonReviewer?.fullName || '',
          imageSrc: lesson?.lessonReviewer?.profileImageProviderUrl || '',
        },
      };
    })
    .filter(Boolean) as FutureSkillsProps[]; // Remove null entries

  // Sort by notedDate in descending order
  return futureSkillsData.sort(
    (a, b) => new Date(b.notedDate).getTime() - new Date(a.notedDate).getTime(),
  );
};

export const getSkillLabel = (str: string = '') => toSentenceCase(str.replaceAll('_', ' '));

export const processLessonReflections = (
  lessons:
    | GetPlayerReflectionByPlayerIdQuery['lessons']
    | GetCoachReflectionsBySubmitterIdQuery['lessons']
    | GetCoachAllPlayersReflectionsQuery['lessons'],
  flatten: boolean = false,
): ProcessedReflection[] => {
  const extractPrimaryMedia = (
    comments: (typeof lessons)[0]['lessonReflections'][0]['lessonReflectionComments'],
  ) => {
    const primaryMediaComment = comments
      .filter((comment) => comment.lessonReflectionFiles.length > 0)
      .sort((a, b) => a.order - b.order)?.[0];

    return primaryMediaComment?.lessonReflectionFiles?.[0] ?? null;
  };

  const processLesson = (
    lesson: (typeof lessons)[0],
    reflection?: (typeof lesson)['lessonReflections'][0],
  ) => {
    const skills = new Set<string>();
    const reflectionDetails: ReflectionDetail[] = [];
    let media: MediaType | null = null;
    let detail = '';

    const comments =
      reflection?.lessonReflectionComments ||
      lesson.lessonReflections?.[0]?.lessonReflectionComments ||
      [];
    comments.forEach((comment) => {
      skills.add(comment.skill || comment.customSkill || '');
      reflectionDetails.push({
        skill: comment.skill || comment.customSkill || '',
        sentiment: comment.sentiment,
        detail: comment.details || '',
        avatarInfo: {
          imageSrc: reflection?.lessonSubmitter?.profileImageProviderUrl || '',
          name: reflection?.lessonSubmitter?.fullName || '',
        },
        media: comment.lessonReflectionFiles.map((file) => ({
          src: file.url,
          type: file.fileType,
          format: file.fileFormat,
        }))?.[0],
      });
    });

    if (!media) {
      const primaryMedia = extractPrimaryMedia(comments);
      if (primaryMedia) {
        media = {
          src: primaryMedia.url,
          type: primaryMedia.fileType,
          format: primaryMedia.fileFormat,
        };
        detail = comments[0]?.details || '';
      }
    }

    return {
      lessonId: lesson.id,
      skills: Array.from(skills),
      reflectionDetails,
      media,
      detail,
      date: lesson?.times?.[0]?.startDateTime || '',
      coachAvatarInfo: {
        imageSrc: lesson.ownerProfile?.profileImageProviderUrl || '',
        name: lesson.ownerProfile?.fullName || '',
      },
      playerAvatarInfo: {
        imageSrc: isAllPlayersLesson(lesson)
          ? lesson.participants?.[0].userProfile?.profileImageProviderUrl || ''
          : '',
        name: isAllPlayersLesson(lesson)
          ? lesson.participants?.[0].userProfile?.fullName || ''
          : '',
      },
      isCoachReflectionPending: lesson.lessonReflections.length === 0,
      isPlayerReflectionPending: isPlayerLesson(lesson)
        ? lesson.lessonPlayerSkillAssessments.length === 0
        : false,
      lessonOwner: (lesson?.ownerProfile as UserProfiles) || null,
      player: isAllPlayersLesson(lesson)
        ? (lesson.participants?.[0]?.userProfile as UserProfiles) || null
        : null,
    };
  };

  if (flatten && isAllPlayersLessons(lessons)) {
    return lessons.flatMap((lesson) => {
      const reviewerIds = lesson.lessonReflections.map(
        (reflection) => reflection.lessonReviewer?.id,
      );

      const filteredParticipants = lesson.participants.filter(
        (participant) => !reviewerIds.includes(participant.userProfile?.id),
      );

      return filteredParticipants.map((participant) => {
        const lessonClone = { ...lesson, participants: [participant] };
        return processLesson(lessonClone);
      });
    });
  } else {
    return lessons.map((lesson) => processLesson(lesson));
  }
};

export const isPlayerLesson = (
  lesson: any,
): lesson is GetPlayerReflectionByPlayerIdQuery['lessons'][0] => {
  return !!lesson.lessonPlayerSkillAssessments;
};

export const isAllPlayersLessons = (
  lessons: any,
): lessons is GetCoachAllPlayersReflectionsQuery['lessons'] => !!lessons?.[0]?.participants;

export const isAllPlayersLesson = (
  lessons: any,
): lessons is GetCoachAllPlayersReflectionsQuery['lessons'][0] => !!lessons?.participants;

export const getSortedByPendingReflection = (
  reflections: ProcessedReflection[],
  accountType: AccountType,
): ProcessedReflection[] => {
  return reflections.toSorted((a, b) => {
    const isPendingA =
      accountType === AccountType.Coach ? a.isCoachReflectionPending : a.isPlayerReflectionPending;

    const isPendingB =
      accountType === AccountType.Coach ? b.isCoachReflectionPending : b.isPlayerReflectionPending;

    // Prioritize pending reflections
    if (isPendingA !== isPendingB) {
      return Number(isPendingB) - Number(isPendingA);
    }

    // Sort by date (oldest first)
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });
};
