import { capitalize, snakeCase, uniqBy } from 'lodash';
import { FeedbackSentimentEnum, LessonSkillsEnum, SportsEnum } from 'types/generated/client';
import { toSentenceCase } from 'utils/shared/string/toSentenceCase';
import { getSkillLabel } from 'components/SkillAnalysis/utils';
import { VideoAnalysisType } from './types';

export const getFormattedSkills = (videoAnalaysis: VideoAnalysisType) =>
  videoAnalaysis.videoAnalysisRequestedSkillsReviews.map((review) => {
    const skill = review.videoLessonSkills.customSkill || review.videoLessonSkills.skill;
    return capitalize(skill?.replaceAll('_', ' '));
  });

export const isVideoDurationValid = (file: File, maxSeconds: number): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = () => {
      const duration = video.duration;
      window.URL.revokeObjectURL(video.src); // Clean up the object URL

      if (duration > maxSeconds) {
        resolve(false); // Video exceeds the limit
      } else {
        resolve(true); // Video is within the limit
      }
    };

    video.onerror = (error) => {
      reject(error);
    };
  });
};

export const transformCustomSkillToDb = (customSkill: string) =>
  customSkill.toUpperCase().replaceAll(' ', '_');

export const getLessonSkills = (allSkills: Array<string> = []) => {
  const predefinedSkills = (Object.values(LessonSkillsEnum) as string[]).map((skill) => ({
    label: toSentenceCase(skill),
    value: skill,
  }));
  const predefinedSkillsSet = new Set(predefinedSkills.map((skill) => skill.value));

  const customSkillsFormatted = allSkills
    .filter((skill) => !predefinedSkillsSet.has(skill))
    .map((customSkill) => ({
      label: getSkillLabel(customSkill),
      value: transformCustomSkillToDb(customSkill),
    }));

  return {
    allSkills: uniqBy([...predefinedSkills, ...customSkillsFormatted], 'value'),
    predefinedSkills: uniqBy(predefinedSkills, 'value'),
    customSkills: uniqBy(customSkillsFormatted, 'value'),
  };
};
export const toggleSkillSelection = (skills: string[], skill: string): string[] => {
  if (skills.includes(skill)) {
    return skills.filter((item) => item !== skill);
  }
  return [...skills, skill];
};

export const sportStyles = {
  [SportsEnum.Tennis]: {
    bgColor: 'bg-color-tennis-bg',
    textColor: 'text-color-tennis-text',
    label: 'Tennis',
  },
  [SportsEnum.Pickleball]: {
    bgColor: 'bg-color-pickleball-bg',
    textColor: 'text-color-pickleball-text',
    label: 'Pickleball',
  },
};

export const sentimentMapper = {
  [FeedbackSentimentEnum.Positive]: 'success',
  [FeedbackSentimentEnum.Neutral]: 'default',
  [FeedbackSentimentEnum.Critical]: 'critical',
};
