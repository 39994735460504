import * as React from 'react';
import NextImage from 'next/image';
import { getCoachPageUrl } from 'constants/pages';
import { EMPTY_COACH_AVATAR_SRC } from 'constants/user';
import { SportsEnum } from 'types/generated/client';
import { convertUnitPriceToFormattedPrice } from 'utils/shared/money/convertUnitPriceToFormattedPrice';
import { pluralize } from 'utils/shared/pluralize';
import Location from 'svg/Location';
import Players from 'svg/Players';
import Price from 'svg/Price';
import { ButtonLink } from 'components/Button';
import Link from 'components/Link';
import { sportStyles } from 'components/VideoAnalysis/utils';
import classNames from 'styles/utils/classNames';

interface Props {
  id: string;
  slug: string;
  photoUrl?: string;
  certificationImages?: string[];
  fullName: string;
  price: number;
  isLink?: boolean;
  distance: number;
  isBookButton?: boolean;
  followersCount?: number;
  defaultSport?: string;
}

const isNotNullOrUndefined = (value: number | undefined): value is number => {
  return value !== undefined && value >= 0;
};

const Item = ({
  name,
  Icon,
}: {
  name: string | number;
  Icon: ({ className }: React.SVGProps<SVGSVGElement>) => JSX.Element;
}) => {
  return (
    <div className="mr-ds-md flex items-center text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
      <Icon className="mr-1 h-4 w-4 text-color-bg-lightmode-icon dark:text-color-bg-lightmode-icon" />{' '}
      <span className="typography-product-caption">{name}</span>
    </div>
  );
};

const Content = ({
  photoUrl,
  certificationImages,
  fullName,
  price,
  distance,
  slug,
  isBookButton = false,
  followersCount,
  defaultSport,
}: Props) => {
  const sport = sportStyles[defaultSport as SportsEnum];

  return (
    <div className="relative h-full w-full">
      <div>
        <NextImage
          src={photoUrl || EMPTY_COACH_AVATAR_SRC}
          alt={fullName}
          className={classNames(
            'aspect-square object-cover object-center',
            isBookButton
              ? 'relative h-48 w-full rounded-lg border border-color-border-input-lightmode dark:border-color-border-input-darkmode'
              : '"h-[20.375rem] w-full rounded-lg',
          )}
          loading="lazy"
          height={isBookButton ? 192 : 326}
          width={isBookButton ? 302 : 326}
        />
      </div>
      <div className="mt-ds-sm flex items-center justify-between">
        <div className="typography-product-heading-compact text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
          {fullName}
        </div>

        <div className="item-center flex">
          {certificationImages?.map((image) => (
            <img
              src={image}
              alt={fullName}
              className="w-full"
              loading="lazy"
              height={24}
              width={64}
            />
          ))}
        </div>
      </div>
      {!!price && (
        <div className="mt-ds-sm flex flex-wrap">
          <Item
            name={`${convertUnitPriceToFormattedPrice(price).priceDisplay} ${
              !!isBookButton ? '+' : ''
            } per lesson`}
            Icon={Price}
          />
        </div>
      )}
      {!!distance && (
        <div className="mt-ds-sm flex text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          <Location className="mr-1 h-4 w-4 text-color-bg-lightmode-icon dark:text-color-bg-darkmode-icon" />{' '}
          <span className="typography-product-caption">
            {pluralize({
              count: Math.round(distance),
              singular: 'mile',
              plural: 'miles',
            })}
          </span>
        </div>
      )}
      <div className="flex justify-between gap-2">
        {isNotNullOrUndefined(followersCount) && (
          <div className="mt-ds-sm flex text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            <Players className="mr-1 h-4 w-4 text-color-bg-lightmode-icon dark:text-color-bg-darkmode-icon" />{' '}
            <span className="typography-product-caption">
              {pluralize({
                count: followersCount,
                singular: 'follower',
                plural: 'followers',
              })}
            </span>
          </div>
        )}
        {sport && (
          <div
            className={`flex h-5 items-center rounded-2xl ${sport.bgColor} px-2 text-xs ${sport.textColor} lg:h-6 lg:px-3 lg:text-sm`}
          >
            {sport.label}
          </div>
        )}
      </div>
      {!!isBookButton && (
        <div className="absolute bottom-0 left-0 right-0 flex flex-row items-center justify-center pb-4 ">
          <ButtonLink
            href={getCoachPageUrl(slug)}
            variant="primary"
            className=""
            isInline
            size="sm"
          >
            Book
          </ButtonLink>
        </div>
      )}
    </div>
  );
};

export default function CardCoach({ isLink = true, ...props }: Props) {
  if (isLink) {
    return (
      <Link href={getCoachPageUrl(props.slug)} className="w-full">
        <Content {...props} />
      </Link>
    );
  }

  return <Content {...props} />;
}
